import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const SectionWrapper = styled.section`
  background-color: white;
  padding: 48px 0;

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  *{
    font-family: "Inter", Helvetica, sans-serif;
  }
  .container{
    padding: 0;
    margin: 0;
    max-width: none;
  }
  
  .content_wrapper{
    padding: 0;
    
    &__title{
      color: ${grayscale[500]};
      font-family: "Citrina", Helvetica, sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      margin: 0 32px 16px 32px;

      @media ${device.tablet}{
        text-align: center;
        width: fit-content;
        margin: 0 auto 16px auto;
      }
      
      @media ${device.desktopLG}{
        font-size: 28px;
        line-height: 33.6px;
        margin-bottom: 24px;
      }

      @media ${device.desktopXXL}{
        font-size: 48px;
        line-height: 57.6px;
      }
    }

    &__description{
      color: ${grayscale[500]};
      font-weight: 400;
      font-size: 18px;
      line-height: 21.78px;
      margin: 0 32px;
      text-align: center;

      @media ${device.tablet} {
        margin: 0 36px;
      }
    }
    &__benefits{
      padding: 0 18px;

      @media ${device.tablet} {
        padding: 0 30px 0 20px;
      }

      @media ${device.desktopLG} {
        display: flex;
        justify-content: center;
      }

      .react-multi-carousel-track{
        @media ${device.desktopLG} {
          width: 90%;
        }

        @media ${device.desktopXXL}{
          width: 80%;
        }

        .react-multi-carousel-item{

          @media ${device.desktopLG} {
            display: flex;
            justify-content: center ;
          }
        }
      }

      &__card{
        margin: 0 16px;
        width: 194px;

        @media ${device.tablet} {
          margin: 0;
        }

        @media ${device.desktopXXL}{
          width: 242px;
        }

        &__title{
          height: 82px;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &__description{
          height: 136px;
          font-weight: 400;
          font-size: 14px;
          line-height: 16.94px;

          @media ${device.desktopLG}{
            display: flex;
            align-items: center;
          }
        }
      }
    }
    &__open_account{
      margin: 32px auto 0 auto;
      width: 86%;
      display: flex;
      background-color: ${primary[500]};


      @media ${device.tablet}{
        width: 60%;
      }

      @media ${device.desktopLG}{
        margin-top: 0;
        width: 40%;
      }

      @media ${device.desktopXXL}{
        padding-top: -30px;
        width: 36%;
      }
    }

  }
`
