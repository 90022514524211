import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import { primary } from "src/styles/newColors"
import styled from "styled-components"

export const SectionWrapper = styled.section`
  background-color: ${grayscale[100]};
  padding: 40px 0;

  @media ${device.tablet}{
    padding: 48px 0;
  }

  @media ${device.desktopLG}{
    padding: 96px 0;
  }

  .container{
    margin: 0 auto;
    padding: 0 22px;
  }
  .content_wrapper{
    margin: 0;
    padding: 0;

    &__title{
      font-family: "Citrina", Helvetica, sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      color: ${grayscale[500]};
      margin-bottom: 32px;
      text-align: center;
      
      @media ${device.tablet}{
        margin-bottom: 20px;
      }

      @media ${device.desktopLG}{
        font-size: 28px;
        line-height: 33.6px;
      }

      @media ${device.desktopXXL}{
        font-size: 48px;
        line-height: 52.8px;
        margin-bottom: 28px;
      }
    }
    
    &__blog_container{
      display: flex;
      flex-direction: column;

      @media ${device.tablet}{
        flex-direction: row;
      }

      .card_wrapper{
        max-width: 400px;
        margin: 12px auto;

        @media ${device.tablet}{
          width: 30%;
        }

        @media ${device.desktopLG}{
          min-height: fit-content;
        }

        @media ${device.desktopXXL}{
          width: 31%;
        }

        &__image_wrapper{
          @media ${device.tablet} {
            height: auto;
          }
        }

        &__title_wrapper{
          margin-bottom: 8px;
        }
        &__button_wrapper{
          &__read_more{
            color: ${primary[500]};
          }
        }
      }
    }
  }
`
