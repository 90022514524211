import { device } from "src/styles/breakpoints"
import { grayscale } from "src/styles/colors"
import styled from "styled-components"

export const SectionWrapper = styled.section`
  padding: 40px 0;

  @media ${device.tablet}{
    padding: 48px 0;
  }

  @media ${device.desktopXXL}{
    padding: 96px 0;
  }

  .container{
    margin: 0 auto;
  }

  .main{
    padding: 0 12px;

    @media ${device.desktopLG}{
      padding: 0;
    }

    &__title{
      font-family: "Citrina", Helvetica, sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 28.8px;
      text-align: center;
      margin-bottom: 32px;

      @media ${device.desktopLG}{
        font-size: 28px;
        line-height: 33.6px;
      }

      @media ${device.desktopXXL}{
        font-size: 48px;
        line-height: 57.6px;
      }
    }
    &__content{
      @media  ${device.tablet}{
        display: flex;
        justify-content: space-between;
      }

      @media ${device.desktopLG}{
        align-items: center;
      }

      &__conditions{
        @media ${device.tablet}{
          width: 42%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        @media ${device.desktopLG}{
          padding-right: 76px;
          width: 60%;
        }

        @media ${device.desktopXXL}{
          width: 50%;
        }

        &__card{

          @media ${device.desktopLG}{
            width: 60%;
          }

          &--1{
            margin: 48px 0;

            @media ${device.desktopLG}{
              margin-left: auto;
            }
          }
          
          &__title{
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            padding: 10px 0;
          }
          &__description{
            font-weight: 400;
            font-size: 16px;
            line-height: 19.36px;
            color: ${grayscale[500]};
          }
        }
      }
      
      &__image_container{
        @media ${device.tablet} {
          flex: auto;
          display: flex;
          justify-content: flex-end;
          box-sizing: border-box;
          max-width: fit-content;
        }

        @media ${device.desktopLG}{
          width: 40%;
        }
        
        &__image{
          @media ${device.desktopLG} {
            display: flex;
            width: 99%;
            height: auto;
          }
        }
      }
    }
  }   
`
